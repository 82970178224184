"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AccountModel = exports.AccountLevel = void 0;
const typebox_1 = require("@sinclair/typebox");
const timezone_1 = require("../../data/timezone");
const timestamp_json_1 = require("../timestamp-json");
const AccountPreferences = typebox_1.Type.Object({ timezone: typebox_1.Type.Optional(timezone_1.TimezoneName) }, { $id: 'AccountPreferencesModel' });
exports.AccountLevel = typebox_1.Type.Union([
    typebox_1.Type.Literal('admin'),
    typebox_1.Type.Literal('superadmin'),
]);
exports.AccountModel = typebox_1.Type.Object({
    id: typebox_1.Type.String({ format: 'uuid' }),
    email: typebox_1.Type.String({ format: 'email' }),
    preferences: AccountPreferences,
    accountLevel: exports.AccountLevel,
    ...timestamp_json_1.TimestampsJson.properties,
}, { $id: 'AccountModel' });
